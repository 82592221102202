/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

function getDocumentsByString(search) {
  return axios.post(`${API_PATH}v1/documents/search`, { search });
}

function getDocumentById(id) {
  return axios.get(`${API_PATH}v1/documents/get_document/${id}`);
}

function getAllDocumentsByType(id) {
  return axios.get(`${API_PATH}v1/documents/${id}`);
}

export default {
  getDocumentById,
  getAllDocumentsByType,
  getDocumentsByString,
};
