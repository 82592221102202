import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import GroupsService from "../services/groups.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";
import Loader from "./Loader";
import VolumeList from "./VolumeList";

const GroupDetail = (props) => {
  const [group, setGroup] = useState();
  let { groupId } = useParams();

  useEffect(() => {
    GroupsService.getGroup(groupId).then(
      (response) => {
        setGroup(response.data);
      },
      (error) => {
        const _group =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setGroup(_group);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [groupId]);

  if (!!group) {
    return (
      <DynamicContent
        title={`Grupo - ${group.small_description}`}
        content={group.long_description}
      >
        <VolumeList volumes={group.volumes} />
      </DynamicContent>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!group} />
      </div>
    );
  }
};

export default GroupDetail;
