/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { confirm } from "../actions/auth";
import { Alert } from "react-bootstrap";
const Confirmation = () => {
  const [searchParams] = useSearchParams();
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const confirmationToken = searchParams.get("confirmation_token");

  useEffect(() => {

    if (!done && !error) {
      dispatch(confirm(confirmationToken))
        .then(() => {
          setDone(true);
          setError(false);
        })
        .catch((error) => {
          setError(true);
        });
    }
  }, [error, done, searchParams, dispatch, confirmationToken]);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          {done && (
            <Alert variant="success">Registo confirmado com sucesso.</Alert>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              Erro a confirmar registo.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
