/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { sortBy, prop, isEmpty, isNil } from "ramda";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../actions/auth";

const buildItem = (page, currentUser) => {
  return sortBy(prop("position"), page.children).map((item) => {
    if (!isNil(currentUser) && item.url === "/register") {
      return null;
    } else {
      if (item.open || !isNil(currentUser)) {
        return (
          <li className="nav-item" key={Math.random()}>
            {item.is_link && item.external && (
              <a
                href={item.url}
                className="dropdown-item"
                rel="noreferrer"
                target={item.new_window ? "_blank" : ""}
              >
                {item.description}
              </a>
            )}
            {!item.external && (
              <Link
                to={item.is_link ? item.url : `/pages/${item.slug}`}
                key={item.id}
                className="dropdown-item"
              >
                {item.description}
              </Link>
            )}
          </li>
        );
      }
    }
  });
};

const buildMainMenuPart = (props, currentUser) => {
  return sortBy(prop("id"), props.dynamicPages).map((page) => {
    if (
      (isEmpty(page.my_children) || isEmpty(page.children)) &&
      !page.is_footer
    ) {
      return (
        <li className="nav-item" key={`${Math.random()}-${page.id}`}>
          {page.is_link && page.external && (
            <a
              href={page.url}
              className="nav-link active"
              rel="noreferrer"
              target={page.new_window ? "_blank" : ""}
            >
              {page.description}
            </a>
          )}
          {!page.external && (
            <Link
              to={page.is_link ? page.url : `/pages/${page.slug}`}
              className="nav-link active"
            >
              {page.description}
            </Link>
          )}
        </li>
      );
    } else {
      return (
        (page.open || !isNil(currentUser)) &&
        !page.is_footer && (
          <li className="nav-item dropdown" key={`${Math.random()}-${page.id}`}>
            <Link
              to={page.is_link ? page.url : `/pages/${page.slug}`}
              className="nav-link dropdown-toggle"
              id="navbarDropdown1"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {page.description}
            </Link>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
              {buildItem(page, currentUser)}
            </ul>
          </li>
        )
      );
    }
  });
};

const NavBar = (props) => {
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
  };

  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <nav className="navbar navbar-expand-xl navbar-dark fixed-top bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img
            src="/logo.png"
            height="100"
            alt="Sociedade Geológica de Portugal"
            title="Sociedade Geológica de Portugal"
            className="navbar-brand"
          />
        </Link>
        <h1 className="text-white text-uppercase ms-sm-0 display">
          Sociedade Geológica de Portugal
        </h1>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse " id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="/" >
                Início
              </Link>
            </li>

            {props.dynamicPages !== undefined &&
              buildMainMenuPart(props, currentUser)}
          </ul>
        </div>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="special-lf">
            <a
              href="https://www.facebook.com/profile.php?id=100063629794428"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/facebook.png"
                height="70px"
                alt="Facebook - Sociedade Geológica de Portugal"
                title="Facebook - Sociedade Geológica de Portugal"
                className="navbar-brand"
              />
            </a>

            <a
              href="https://www.youtube.com/channel/UChrNWSguH2tPEUEC516ASxg"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/youtube.png"
                height="70px"
                alt="Youtube - Sociedade Geológica de Portugal"
                title="Youtube - Sociedade Geológica de Portugal"
                className="navbar-brand"
              />
            </a>

            {isNil(currentUser) && (
              <Link to="/login">
                <button
                  type="button"
                  className="btn btn-light text-dark btn-sm me-4 mt-s-2 button-md"
                >
                  <img
                    src="/user.png"
                    height="40px"
                    alt="Loginl"
                    title="Sociedade Geológica de Portugal"
                    className="navbar-brand"
                  />
                  <i className="fas fa-user" aria-hidden="true"></i>
                  &nbsp;Login
                </button>
              </Link>
            )}
            {!isNil(currentUser) && (
              <Link onClick={logOut} to="/">
                <button
                  type="button"
                  className="btn btn-light text-dark btn-sm me-4  button-md"
                >
                  <img
                    src="/user.png"
                    height="40px"
                    alt="Loginl"
                    title="Sociedade Geológica de Portugal"
                    className="navbar-brand"
                  />
                  <i className="fas fa-user" aria-hidden="true"></i>
                  Logout
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
