import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import { sortBy, prop, filter, propEq, last, isEmpty } from "ramda";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import isNil from "ramda/src/isNil";
import { update } from "../actions/auth";

const ProfileData = (props) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const [currentCountry, setCurrentCountry] = useState({});
  const [currentOccupation, setCurrentOccupation] = useState({});
  const [currentSa, setCurrentSa] = useState([]);
  const [currentEl, setCurrentEl] = useState({});

  const getkeyValue = (data, id) => {
    const desc = prop("description", last(filter(propEq("id", id), data)));

    return !isNil(id)
      ? {
          value: id.toString(),
          label: desc,
        }
      : id;
  };

  const { countries, sareas, occupations, elevels, currentUser } = props;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    cp1: Yup.lazy((value) =>
      value === ""
        ? Yup.string()
        : Yup.number("Inválido")
            .typeError("Tem que ser um número")
            .test(
              "len",
              "Tem de ter 4 caracteres",
              (val) => val.toString().length === 4
            )
            .max(9999, "Não pode ser maior que 9999")
    ),
    cp2: Yup.lazy((value) =>
      value === ""
        ? Yup.string()
        : Yup.number("Inválido")
            .typeError("Tem que ser um número")
            .test(
              "len",
              "Tem de ter 3 caracteres",
              (val) => val.toString().length === 3
            )
            .max(999, "Não pode ser maior que 999")
    ),
    country: Yup.object({ value: Yup.string() })
      .required("Campo Obrigatório")
      .nullable(),
  });

  const { message } = useSelector((state) => state.message);
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState, control } = useForm(formOptions);

  const { errors } = formState;

  const sortedElements = (elements) => {
    return sortBy(
      prop("label"),
      elements.map(function (c) {
        return { value: c.id, label: c.description };
      })
    );
  };

  const options = sortBy(
    prop("label"),
    countries.map(function (c) {
      return { value: c.id, label: c.description };
    })
  );

  const handleCountryChange = (value) => {
    setCurrentCountry(value);
  };

  const handleOccupationChange = (value) => {
    setCurrentOccupation(value);
  };

  const handleElLChange = (value) => {
    setCurrentEl(value);
  };

  const handleSaChange = (item) => {
    if (!isEmpty(item) && !!item[0].value) {
      setCurrentSa(item);
    } else {
      setCurrentSa([]);
    }
  };

  useEffect(() => {
    if (!!currentUser.country_id) {
      setCurrentCountry(getkeyValue(countries, currentUser.country_id));
    }
    if (!!currentUser.occupation_id) {
      setCurrentOccupation(getkeyValue(occupations, currentUser.occupation_id));
    }
    if (!!currentUser.education_level_id) {
      setCurrentEl(getkeyValue(elevels, currentUser.education_level_id));
    }
    if (!isEmpty(currentUser.scientific_areas)) {
      setCurrentSa(currentUser.scientific_areas);
    }
  }, [
    countries,
    currentUser.country_id,
    currentUser.education_level_id,
    currentUser.occupation_id,
    currentUser.scientific_areas,
    currentUser.scientifica_area_id,
    currentUser.scientifica_areas,
    elevels,
    occupations,
    sareas,
  ]);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);

    data.country = currentCountry;
    data.scientific_areas = currentSa;
    data.education_level_id = currentEl;
    data.occupation_id = currentOccupation;

    dispatch(update(data))
      .then(() => {
        setDone(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {done && (
        <Alert variant="success">Perfil actualizado com com sucesso.</Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="txt-darkgreen text-center text-uppercase bold mt-5 mb-1">
          Dados Perfil (Email: {currentUser.email})
        </h3>
        <div className="form-row">
          <div className="form-group col">
            <div className="col-12">
              <label>Nome</label>
              <input
                name="name"
                defaultValue={currentUser.name}
                type="text"
                {...register("name")}
                className={`form-control ${errors.name ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{errors.name?.message}</div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <div className="form-group row">
              <div className=" col-3">
                <label>Telefone</label>
                <input
                  defaultValue={currentUser.phone}
                  name="phone"
                  type="text"
                  {...register("phone")}
                  className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.phone?.message}</div>
              </div>
              <div className=" col-9">
                <label>Morada</label>
                <input
                  name="address"
                  type="text"
                  defaultValue={currentUser.address}
                  {...register("address")}
                  className={`form-control ${
                    errors.address ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.address?.message}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col">
            <div className="form-group row">
              <div className="col-7">
                <label>Código Postal - Localidade</label>
                <div className="row">
                  <div className="col-4">
                    <Form.Control
                      name="cp1"
                      type="number"
                      defaultValue={currentUser.cp1}
                      {...register("cp1")}
                      className={`form-control ${
                        errors.cp1 ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.cp1?.message}
                    </div>
                  </div>
                  <div className="col-3">
                    <input
                      name="cp2"
                      defaultValue={currentUser.cp2}
                      maxLength={3}
                      type="number"
                      {...register("cp2")}
                      className={`form-control ${
                        errors.cp2 ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.cp2?.message}
                    </div>
                  </div>
                  <div className="col-5 text-center">
                    <input
                      name="cp_locality"
                      type="text"
                      defaultValue={currentUser.cp_locality}
                      {...register("cp_locality")}
                      className={`form-control ${
                        errors.locality ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.locality?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-5">
                <label>País</label>
                <Select
                  options={options}
                  value={currentCountry}
                  placeholder="País"
                  onChange={(value) => handleCountryChange(value)}
                  className={` ${errors.country ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">
                  {errors.country?.message}
                </div>
              </div>
              <h3 className="txt-darkgreen text-center text-uppercase bold mt-5 mb-1">
                Dados Adicionais (Facultativos)
              </h3>
              <div className=" col-4">
                <label>Ocupação</label>
                <Controller
                  name="occupation_id"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <>
                        <Select
                          {...field}
                          value={currentOccupation}
                          options={sortedElements(occupations)}
                          placeholder="Ocupação"
                          onChange={(value) => handleOccupationChange(value)}
                          className={` ${errors.country ? "is-invalid" : ""}`}
                        />
                        <div className="invalid-feedback">
                          {errors.occupation?.message}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
              <div className=" col-4">
                <label>Hab. Académicas</label>
                <Controller
                  name="education_level_id"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <>
                        <Select
                          {...field}
                          options={sortedElements(elevels)}
                          placeholder="Hab. Académicas"
                          value={currentEl}
                          onChange={(value) => handleElLChange(value)}
                          className={` ${errors.country ? "is-invalid" : ""}`}
                        />
                        <div className="invalid-feedback">
                          {errors.country?.message}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
              <div className=" col-4 mb-5">
                <label>Áreas Cientificas</label>
                <Controller
                  name="scientific_areas"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <>
                        <Select
                          isClearable
                          isMulti
                          {...field}
                          options={sortedElements(sareas)}
                          value={currentSa.map((item) => ({
                            value: item.id || item.value,
                            label: item.description || item.label,
                          }))}
                          placeholder="Áreas Cientificas"
                          onChange={(selected) => handleSaChange(selected)}
                          className={` ${errors.country ? "is-invalid" : ""}`}
                        />
                        <div className="invalid-feedback">
                          {errors.country?.message}
                        </div>
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-group d-flex justify-content-end col-12">
          <button type="submit" className="btn btn-primary m-1 w-25">
            Salvar{" "}
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
          </button>
        </div>
      </form>
      <h6
        className="txt-darkorange  mt-5 mb-1"
        style={{ textAlign: "justify" }}
      >
        <p>Política de dados pessoais:</p>
        <p>
          A SOCIEDADE GEOLÓGICA DE PORTUGAL cumpre a legislação vigente sobre
          proteção de dados pessoais, no âmbito do seu sítio Internet. Sempre
          que ocorram tratamentos de dados pessoais, serão expostos ao
          utilizador os termos e finalidades do tratamento, sendo obtido o seu
          consentimento expresso para o efeito, sempre que legalmente exigido.
        </p>
      </h6>
      {!loading && message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileData;
