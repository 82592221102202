import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  CONFIRM_FAIL,
  CONFIRM_SUCCESS,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  UPDATE_SUCCESS,
  RESET_PROCESS_FAIL,
  RESET_PROCESS_SUCCESS,
} from "./types";

import AuthService from "../services/auth.service";

export const confirm = (data) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: null,
  });

  return AuthService.confirm(data).then(
    (response) => {
      dispatch({
        type: CONFIRM_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      let message = "";
      if (error.response.status === 422) {
        message = "Utilizador / Email existente";
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      dispatch({
        type: CONFIRM_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const signup = (data) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: null,
  });

  return AuthService.signup(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      let message = "";
      if (error.response.status === 422) {
        message = "Utilizador / Email existente";
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const update = (data) => (dispatch) => {
  dispatch({
    type: SET_MESSAGE,
    payload: null,
  });

  return AuthService.updateProfile(data).then(
    (response) => {
      dispatch({
        type: UPDATE_SUCCESS,
        payload: { user: response.data },
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      let message = "";
      if (error.response.status === 422) {
        message = "Utilizador / Email existente";
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
      dispatch({
        type: UPDATE_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      let message = "";
      if (error.response.status === 401) {
        message = "Utilizador / Email existente";
      } else {
        message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const resetPassword = (email) => (dispatch) => {
  return AuthService.recoverPassword(email).then(
    (data) => {
      dispatch({
        type: RESET_REQUEST_SUCCESS,
        payload: { result: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: RESET_REQUEST_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const processResetPassword = (userData) => (dispatch) => {
  return AuthService.processRecoverPassword(userData).then(
    (data) => {
      dispatch({
        type: RESET_PROCESS_SUCCESS,
        payload: { result: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: RESET_PROCESS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
