/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

const getCountries = () => {
  return axios.get(`${API_PATH}/v1/countries/`);
};

export default {
    getCountries,
};
