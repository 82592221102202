import React, { useState } from "react";
import DocumentsService from "../services/documents.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Loader from "./Loader";
import { isEmpty, sortWith, prop, descend, isNil } from "ramda";

const DocumentSearch = () => {
  const [documents, setDocuments] = useState(null);
  const [search, setSearch] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const onChangeSearch = (e) => {
    setDocuments(null);
    setSearch(e.target.value);
  };

  const resetSearch = () => {
    setSearch("");
    setDocuments([]);
  };

  const submitSearch = () => {
    setIsloading(true);
    DocumentsService.getDocumentsByString(search).then(
      (response) => {
        setDocuments(response.data.hits);
        setIsloading(false);
      },
      (error) => {
        const _documents =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setDocuments(_documents);
        setIsloading(false);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  return (
    <div>
      <div className="row pb-4">
        <div className="col-md-12">
          <div className=" mx-auto">
            <h2 className="txt-darkorange bold ">Pesquisa de documentos</h2>
          </div>
        </div>
        <div className="col-md-6">
          <Form.Control
            type="text"
            name="search"
            onChange={onChangeSearch}
            value={search}
          />
        </div>
        <div className="col-md-6">
          {" "}
          <Button variant="primary" onClick={submitSearch}>
            Procurar
          </Button>{" "}
          <Button variant="primary" onClick={resetSearch} className="px-3">
            Limpar
          </Button>
        </div>
      </div>

      <>
        {!isNil(documents) && !isEmpty(documents) && (
          <DynamicContent title={`Documentos`} content={""}>
            {sortWith(
              [descend(prop("year")), descend(prop("subject"))],
              documents
            ).map((d, index2) => (
              <div className="p-2" key={index2}>
                <a
                  href={`/document/${d.slug}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {d.year} - {d.subject} <br /> {d.authors_list}, {d.country}{" "}
                  <br /> {d.authors}
                </a>
              </div>
            ))}
          </DynamicContent>
        )}

        {!isNil(search) &&
          isEmpty(documents) &&
          !isLoading &&
          !isEmpty(search) && (
            <p>Não foram encontrados documentos para a pesquisa introduzida</p>
          )}

        {isLoading && (
          <div className="d-flex justify-content-center">
            <Loader isLoading={isLoading} />
          </div>
        )}
      </>
    </div>
  );
};

export default DocumentSearch;
