/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";

import { API_PATH } from "../config";

function getAllAgs() {
  return axios.get(`${API_PATH}v1/ags`, { headers: authHeader() });
}

function getAg(id) {
  return axios.get(`${API_PATH}v1/ags/${id}`, { headers: authHeader() });
}

export default {
  getAllAgs,
  getAg,
};
