/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useCallback, useState } from "react";
import { install, gtag } from 'ga-gtag';
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";

import Login from "./components/Login";
import NavBar from "./components/NavBar";

import Confirmation from "./components/Confirmation";
import PasswordResetProcess from "./components/PasswordResetProcess";
import CarouselComponent from "./components/Carousel";
import Register from "./components/Register";
import Home from "./components/Home";
import Actuality from "./components/Actuality";
import Connection from "./components/Connection";
import PasswordReset from "./components/PasswordReset";

import Profile from "./components/Profile";
import Footer from "./components/Footer";
import DynamicPage from "./components/DynamicPage";
import ActualityDetail from "./components/ActualityDetail";
import GroupDetail from "./components/GroupDetail";
import Document from "./components/Document";
import DocumentSearch from "./components/DocumentSearch";
import DocumentDetail from "./components/DocumentDetail";

import Ag from "./components/Ag";
import { filter, propEq } from "ramda";

import DynamicPagesService from "./services/dynamic-page.service";
import ActualitiesService from "./services/actualities.service";

import { logout } from "./actions/auth";

import { history } from "./helpers/history";

import EventBus from "./common/EventBus";

const App = () => {

  const hostname = window && window.location && window.location.hostname;

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const [dynamicPages, setDynamicPages] = useState([]);
  const [dynamicFooter, setDynamicFooter] = useState([]);

  const [actualities, setActualities] = useState([]);
  const [linkTypes, setLinkTypes] = useState([]);
  const [elevels, setElevels] = useState([]);
  const [sareas, setSareas] = useState([]);
  const [occupations, setOccupations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  let [topCarItems, setTopCarItems] = useState([]);
  let [lowerCarItems, setLowerCarItems] = useState([]);

  const withRouter = (Component) => {
    const Wrapper = (props) => {
      const history = useNavigate();
      return <Component history={history} {...props} />;
    };
    return Wrapper;
  };

  install('G-RK803BGEBZ'); 

  function _ScrollToTop(props) {
    const { pathname } = useLocation();

    if (hostname.includes("socgeol.pt")) {
      gtag('event', window.location.pathname, { 'method': 'Google' });
    }

    if (pathname !== "/register" && pathname !== "/login") {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
    }
    return props.children;
  }

  useEffect(() => {
    setIsLoading(true);
    ActualitiesService.getAllActualities().then(
      (response) => {
        setElevels(response.data.elevels);
        setSareas(response.data.sas);
        setOccupations(response.data.occupations);
        setActualities(response.data.news);
        setLinkTypes(response.data.links);
        setTopCarItems(
          response.data.news_c
            .concat(response.data.dp_c)
            .concat(response.data.photos)
        );
        setIsLoading(false);
        setLowerCarItems(response.data.news_h.concat(response.data.dp_h));
      },
      (error) => {
        setActualities([]);
        setIsLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    DynamicPagesService.getDynamicPages().then(
      (response) => {
        setDynamicPages(response.data);
        setDynamicFooter(filter(propEq("is_footer", true), response.data));
      },
      (error) => {
        setDynamicPages(undefined);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  const ScrollToTop = withRouter(_ScrollToTop);

  return (
    <BrowserRouter history={history}>
      <div>
        <div className="container mt-5 pt-10">
          <NavBar dynamicPages={dynamicPages} loggedUser={currentUser} />
          <main className="mt-5">
            <div className="container bg-light pt-3">
              <CarouselComponent topCarItems={topCarItems} />
              <ScrollToTop>
                <div className="mt-3">
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <Home
                          actualities={actualities}
                          dynamicPages={dynamicPages}
                          lowerCarItems={lowerCarItems}
                          topCarItems={topCarItems}
                          isLoading={isLoading}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/pages/:pageId"
                      element={<DynamicPage />}
                    />
                    <Route exact path="/login" element={<Login />} />
                    <Route
                      exact
                      path="/password_recover"
                      element={<PasswordReset />}
                    />
                    <Route
                      path="/profile"
                      element={
                        !!currentUser ? (
                          <Profile
                            sareas={sareas}
                            occupations={occupations}
                            elevels={elevels}
                          />
                        ) : (
                          <Navigate to="/" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/actualites_pt"
                      element={
                        <Actuality national={true} actualities={actualities} />
                      }
                    />
                    <Route
                      exact
                      path="/actualites_int"
                      element={
                        <Actuality national={false} actualities={actualities} />
                      }
                    />
                    <Route
                      exact
                      path="/links_pt"
                      element={
                        <Connection national={true} linkTypes={linkTypes} />
                      }
                    />
                    <Route
                      exact
                      path="/links_int"
                      element={
                        <Connection national={false} linkTypes={linkTypes} />
                      }
                    />
                    <Route
                      exact
                      path="/actualities/:actualityId"
                      element={<ActualityDetail />}
                    />
                    <Route
                      exact
                      path="/groups/:groupId"
                      element={<GroupDetail />}
                    />
                    <Route
                      exact
                      path="/publications/:documentTypeId"
                      element={<Document />}
                    />

                    <Route
                      exact
                      path="/document_search"
                      element={<DocumentSearch />}
                    />

                    <Route
                      path="/document/:documentId"
                      element={<DocumentDetail />}
                    />

                    <Route
                      path="/ag"
                      element={!!currentUser ? <Ag /> : <Navigate to="/" />}
                    />
                  </Routes>
                  <div id="scroller"></div>
                </div>
              </ScrollToTop>
              <Routes>
                <Route
                  exact
                  path="/new_password/:token"
                  element={<PasswordResetProcess />}
                />
                <Route
                  exact
                  path="/register"
                  element={<Register history={history} />}
                />
                <Route
                  exact
                  path="/users/confirmation"
                  element={<Confirmation />}
                />
              </Routes>
              <Footer dynamicFooter={dynamicFooter} />
            </div>
          </main>
        </div>
        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
