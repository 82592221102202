import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import DocumentsService from "../services/documents.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";
import Loader from "./Loader";
import VolumeList from "./VolumeList";

const Document = () => {
  const [documentVolumes, setDocumentVolumes] = useState();
  let { documentTypeId } = useParams();

  useEffect(() => {
    DocumentsService.getAllDocumentsByType(documentTypeId).then(
      (response) => {
        setDocumentVolumes(response.data);
      },
      (error) => {
        const _documentVolumes =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setDocumentVolumes(_documentVolumes);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [documentTypeId]);

  if (!!documentVolumes) {
    return (
      <DynamicContent
        title={`Documentos - ${documentVolumes.description}`}
        content={""}
      >
        <VolumeList volumes={documentVolumes.volumes} />
      </DynamicContent>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!documentVolumes} />
      </div>
    );
  }
};

export default Document;
