import React from "react";

import ActualityList from "./ActualityList";

const Actuality = (props) => {
  const { actualities, national = true } = props;

  return (
    <>
      {actualities !== "" && (
        <ActualityList actualities={actualities} national={national} />
      )}
    </>
  );
};

export default Actuality;
