import { useState, useEffect } from "react";

import DynamicContent from "./DynamicContent";

import AgService from "../services/ag.service";
import EventBus from "../common/EventBus";
import Accordion from "react-bootstrap/Accordion";
import Loader from "./Loader";

import { sortBy, prop } from "ramda";

const Ag = (props) => {
  const [ags, setAgs] = useState([]);

  useEffect(() => {
    AgService.getAllAgs().then(
      (response) => {
        setAgs(response.data);
      },
      (error) => {
        const _ags =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setAgs(_ags);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  if (!!ags) {
    return (
      <>
        <div className="row">
          <div className="col">
            <h2 className="txt-brown text-uppercase mt-3 mb-3">
              Assembleias Gerais
            </h2>
          </div>
        </div>

        <Accordion defaultActiveKey="0">
          {sortBy(prop("order"), ags).map((ag, index) => {
            return (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>{ag.small_description}</Accordion.Header>
                <Accordion.Body>
                  <DynamicContent
                    key={ag.id}
                    className="pt-1"
                    title=""
                    content={ag.description}
                  />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!ags} />
      </div>
    );
  }
};

export default Ag;
