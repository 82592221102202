/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";
import authHeader from "./auth-header";

const signup = (data) => {
  return axios.post(API_PATH + "register", data);
};

const updateProfile = (data) => {
  return axios
    .patch(API_PATH + "v1/update_profile", data, { headers: authHeader() })
    .then((response) => {
      if (response.data.jwt) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response;
    });
};

const recoverPassword = (email) => {
  return axios.get(API_PATH + `password_reset/?email=${email}`);
};

const processRecoverPassword = (data) => {
  return axios.patch(API_PATH + "process_password_reset", data);
};

const login = (email, password) => {
  return axios
    .post(API_PATH + "login", { api_user: { email, password } })
    .then((response) => {
      if (response.data.jwt) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const confirm = (confirmationToken) => {
  return axios.get(
    API_PATH + `confirmation/?confirmation_token=${confirmationToken}`
  );
};

const verifyCaptcha = (value) => {
  const data = { captcha_value: value };
  return axios.post(API_PATH + "verify_captha", data);
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  signup,
  login,
  logout,
  confirm,
  updateProfile,
  recoverPassword,
  processRecoverPassword,
  verifyCaptcha,
};
