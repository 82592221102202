import React from "react";
import DOMPurify from "dompurify";
import TextOverflow from "@kenyip/react-text-overflow";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";

const ActualityBox = (props) => {
  const { actuality } = props;

  const regex = /<p>(.*?)<\/p>/;
  const regexEmpty = /<p[^>]*>(?:\s|&nbsp;)*<\/p>/;

  const corresp = regex.exec(
    DOMPurify.sanitize(actuality.list_description, {
      ALLOWED_TAGS: ["p", ""],
    }).replace(regexEmpty, "")
  );

  // sets first paragraph to ;)
  const firstParagraph = corresp ? corresp[1] : ""; // <p>text1</p>
  return (
    <Card key={`b` + actuality.id}>
        {/* <Card.Img
          variant="top"
          src={
            actuality.exp_url_image
              ? actuality.exp_url_image
              : actuality.first_image
          }
          className="img-fluid"
        /> */}
        <div
          className="card-img-sameh img-fluid"
          style={{
            backgroundImage: `url(${
              actuality.exp_url_image ? actuality.exp_url_image : actuality.first_image
            })`,
            backgroundPosition: "center center fixed",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% auto",
            maxHeight: "100px",
          }}
        ></div>
      <Card.Body>
        <Card.Title key={`bt` + actuality.id}>
          <h6 className="txt-darkorange bold mb-1 flex-fill text-justify">
            <TextOverflow
              maxLine={10}
              character="..."
              className="txt-darkorange bold mb-1"
              tag="span"
              text={DOMPurify.sanitize(actuality.small_description || "", {
                ALLOWED_TAGS: ["p"],
              })}
            />
          </h6>
        </Card.Title>
      </Card.Body>
      <Card.Footer className="text-center">
        <span className="mb-2">{firstParagraph}</span>
          <Link
            to={`/actualities/${actuality.slug}`}
            className="fas fa-angle-right"
          >
            Saiba mais
          </Link>
        </Card.Footer>
    </Card>
  );
};

export default ActualityBox;
