export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAIL = "UPDATE_FAIL";

export const RESET_REQUEST_SUCCESS = "RESET_REQUEST_SUCCESS";
export const RESET_REQUEST_FAIL = "RESET_REQUEST_FAIL";

export const RESET_PROCESS_SUCCESS = "RESET_PROCESS_SUCCESS";
export const RESET_PROCESS_FAIL = "RESET_PROCESS_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_FAIL = "CONFIRM_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
