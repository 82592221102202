import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import UserService from "../services/user.service";
import { Tabs, Tab } from "react-bootstrap";
import EventBus from "../common/EventBus";

import RegistrationDataService from "../services/registration-data.service";
import ProfileQuotes from "./ProfileQuotes";
import ProfileData from "./ProfileData";
import { isEmpty, isNil } from "ramda";

const Profile = (props) => {
  const { sareas, occupations, elevels } = props;

  const { user: currentUser } = useSelector((state) => state.auth);
  const [quotes, setquotes] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    RegistrationDataService.getCountries().then(
      (response) => {
        setCountries(response.data);
      },
      (error) => {
        setCountries([]);
      }
    );
  }, [currentUser.country_id]);

  useEffect(() => {
    UserService.getUserQuotizations().then(
      (response) => {
        if (
          isNil(response.data.length) &&
          (isEmpty(response.data.email) || isNil(response.data.email))
        ) {
          EventBus.dispatch("logout");
        }
        setquotes(response.data || []);
      },
      (error) => {
        const _quotes =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setquotes(_quotes);
      }
    );
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Tabs defaultActiveKey="quotes" id="user-data" className="mb-3">
      <Tab eventKey="quotes" title="Quotas">
        {!isEmpty(quotes) && <ProfileQuotes quotes={quotes} />}
        {isEmpty(quotes) && (
          <p>O seu utilizador ainda não tem quotas para apresentar.</p>
        )}
      </Tab>
      <Tab eventKey="profile" title="Perfil" className="nv">
        <ProfileData
          countries={countries}
          sareas={sareas}
          elevels={elevels}
          occupations={occupations}
          currentUser={currentUser}
        />
      </Tab>
    </Tabs>
  );
};

export default Profile;
