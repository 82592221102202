import { Modal, Button } from "react-bootstrap";

export function TermsAndConditions(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <b>Condições Gerais de Adesão</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Declaro que li os Estatutos da Sociedade Geológica de Portugal e que
          os aceito e respeitarei na condição de associado.
        </p>
        <p>
          A Sociedade Geológica de Portugal, adiante designada por SOCGEOL,
          disponibilizada um serviço online em conformidade com as seguintes
          Condições Gerais de Adesão:
        </p>
        <p>
          1. O serviço online tem por objectivo disponibilizar um conjunto de
          serviços próprios, com funcionalidades públicas e privadas.
        </p>
        <p>
          2. O acesso à área privada do site é efectuado mediante o registo,
          efectuado pelo Utilizador, e a aceitação das presentes Condições de
          Adesão a efectivar, num campo próprio, no termo deste texto.
        </p>
        3. No acto de registo e adesão o Utilizador determina o seu código de
        utilizador (login) e a chave de acesso (password).
        <p></p>
        <p>
          4. O Código de utilizador e a chave de acesso constituem informação de
          uso exclusivo do Utilizador e são exigidos sempre que este pretenda
          aceder às funcionalidades privadas do serviço.
        </p>
        <p>
          5. A SOCGEOL centraliza a gestão de Utilizadores do site,
          identificados através da informação transmitida no acto de registo e
          adesão ao serviço, e os códigos de utilizador e chaves de acesso,
          definidos nos termos do ponto 3.
        </p>
        <p>
          6. O Utilizador pode, em qualquer momento e quanto às funcionalidades
          de acesso privado, alterar os serviços a que pretende aceder através
          do site.
        </p>
        <p>
          7. O primeiro acesso do Utilizador a determinadas funcionalidades
          privadas disponibilizadas pode ser condicionado a activação prévia, a
          efectuar, nos termos que definirem.
        </p>
        <p>
          8. O Utilizador é responsável pela utilização da informação prestada
          no âmbito do registo no site e pela permanente actualização da
          informação pessoal constante do registo.
        </p>
        <p>
          9. A SOCGEOL reserva-se o direito de suspender ou fazer cessar o
          acesso aos serviços do site, assim como de solicitar a confirmação de
          qualquer informação fornecida pelo Utilizador.
        </p>
        <p>
          10. A SOCGEOL pode suspender o acesso aos serviços quando o Utilizador
          não utilize o serviço por período superior a 24 meses;
        </p>
        <p>
          11. Caso o acesso aos serviços seja suspenso o Utilizador pode
          efectuar novo pedido de acesso.
        </p>
        <p>
          12. O Utilizador obriga-se a manter a confidencialidade da chave de
          acesso e a prevenir o seu uso abusivo por parte de terceiros,
          assumindo a total responsabilidade derivada da utilização por
          terceiros dos serviços disponibilizados neste site.
        </p>
        <p>
          13. A SOCGEOL possui os adequados níveis de segurança, contudo não é
          responsável pelos prejuízos provenientes de erros de transmissão e de
          deficiências técnicas ou derivados de interferências ou intercepções
          ilegítimas que ocorram nos sistemas de comunicação e redes
          informáticas utilizados.
        </p>
        <p>
          14. As presentes condições de adesão poderão ser alteradas,
          considerando-se as alterações aceites pelo Utilizador desde que, após
          lhes terem sido comunicadas, o Utilizador não manifeste expressamente
          a intenção de cessar o acesso ao serviço.
        </p>
        <p>
          15. As consultas de dados e informação efectuadas no âmbito deste
          serviço presumem-se efectuadas pelo Utilizador, declinando a SOCGEOL
          qualquer responsabilidade decorrente da utilização abusiva ou
          fraudulenta das informações obtidas.
        </p>
        <p>
          16. Os elementos e informações transmitidos e a requisição de serviços
          solicitados pelo Utilizador no site da Sociedade gozarão de plenos
          efeitos jurídicos, não podendo o Utilizador alegar a falta de
          assinatura para cumprimento das obrigações assumidas por esta via.
        </p>
        <p>
          17. Sem prejuízo do cumprimento das disposições legais sobre esta
          matéria, o Utilizador autoriza a SOCGEOL a proceder ao tratamento
          informático dos dados fornecidos no acto de registo e adesão ao
          serviço ou que, posteriormente, venha a fornecer, podendo estes dados
          ser utilizados para fins estatísticos pela SOCGEOL e para comunicação
          com os Utilizadores.
        </p>
        <p>
          18. Os dados do Utilizador que sejam específicos e particulares dos
          aderentes ao site serão de acesso exclusivo de cada um deles.
        </p>
        <p>
          19. O Utilizador tem o direito de aceder aos elementos a si referentes
          constantes das bases de dados referidas no ponto 17, assim como de
          exigir a sua actualização ou rectificação, bem como de exigir a
          eliminação do seu nome das mesmas verificando-se a cessação do
          serviço.
        </p>
        <p>
          20. A informação prestada no âmbito do registo no site é propriedade
          das entidades que a prestam, comprometendo-se o Utilizador a não
          infringir, qualquer que seja o meio empregue, aqueles direitos de
          propriedade.
        </p>
        <p>
          21. O registo no site é um serviço prestado por prazo indeterminado,
          podendo cessar mediante simples comunicação ao Utilizador.
        </p>
        <p>
          22. <u>Direito ao Esquecimento</u> - O direito ao esquecimento é
          igualmente possível, devendo ser realizado através de pedido por
          escrito, estando limitado à existência de fundamentos válidos para que
          tal não seja possível, nomeadamente obrigações legais.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Li e Aceito</Button>
      </Modal.Footer>
    </Modal>
  );
}
