let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname.includes("qa.socgeol.pt")) {
  backendHost = "https://api.qa.socgeol.pt/api/";
} else if (hostname.includes("socgeol.pt")) {
  backendHost = "https://api.socgeol.pt/api/";
} else {
  backendHost = "http://localhost:3000/api/";
}

export const RECAPTCHA_SITE = "6LeTx_0dAAAAAAexKXldP5ZG1tcTYpjzghDpGWLS";

export const API_PATH = `${backendHost}`;

export const COLORS = [
  { color: "#ffffff", backgroundColor: "#c67200" },
  { color: "#35281f", backgroundColor: "#eedb74" },
  { color: "#ffffff", backgroundColor: "#998f76" },
  { color: "#ffffff", backgroundColor: "#67893f" },
];
