/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

function getAllGroups() {
  return axios.get(`${API_PATH}v1/groups`);
};

function getGroup(id) {
  return axios.get(`${API_PATH}v1/groups/${id}`);
}

export default {
  getAllGroups,
  getGroup,
};
