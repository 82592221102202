import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { sortWith, sortBy, prop, ascend } from "ramda";

const VolumeList = (props) => {
  const { volumes } = props;

  return (
    <Accordion defaultActiveKey="0">
      {sortBy(prop("screen_order"), volumes).map((v, index) => (
        <Accordion.Item eventKey={index} key={`v${index}`}>
          <Accordion.Header>{v.description} </Accordion.Header>
          <Accordion.Body>
            {sortWith(
              [ascend(prop("year")), ascend(prop("subject"))],
              v.documents
            ).map((d, index2) => (
              <div className="p-2" key={index2}>
                <a href={d.url} target={"_blank"} rel="noreferrer noreferrer">
                  {d.subject} ({d.scientific_areas}), {d.year}, {d.country} <br/> {d.authors}
                </a>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default VolumeList;
