/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RegistrationDataService from "../services/registration-data.service";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE } from "../config";

import { sortBy, prop, isEmpty } from "ramda";
import Select from "react-select";
import { signup } from "../actions/auth";
import { TermsAndConditions } from "./TermsConditions";

const Register = (props) => {
  useEffect(() => {
    RegistrationDataService.getCountries().then(
      (response) => {
        setCountries(response.data);
      },
      (error) => {
        setCountries([]);
      }
    );
  }, []);

  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [countries, setCountries] = useState([]);
  const { message } = useSelector((state) => {
    return state.message;
  });
  const [captchaResult, setCaptchaResult] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleRecaptcha = (value) => {
    if (!!value) {
      setCaptchaValue(value);
      setCaptchaResult(true);
      // AuthService.verifyCaptcha(value).then((data) => {
      //   setCaptchaResult(data);
      // });
    }
  };

  const options = sortBy(
    prop("label"),
    countries.map(function (c) {
      return { value: c.id, label: c.description };
    })
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    cp1: Yup.lazy((value) =>
      value === ""
        ? Yup.string()
        : Yup.number("Inválido")
            .typeError("Tem que ser um número")
            .test(
              "len",
              "Tem de ter 4 caracteres",
              (val) => val.toString().length === 4
            )
            // .required("Cp1 é obrigatório")
            .max(9999, "Não pode ser maior que 9999")
    ),
    cp2: Yup.lazy((value) =>
      value === ""
        ? Yup.string()
        : Yup.number("Inválido")
            .typeError("Tem que ser um número")
            .test(
              "len",
              "Tem de ter 3 caracteres",
              (val) => val.toString().length === 3
            )
            // .required("Cp1 é obrigatório")
            .max(999, "Não pode ser maior que 999")
    ),
    // locality: Yup.string().required("Localidade é obrigatória"),
    // address: Yup.string().required("Morada é obrigatória"),
    email: Yup.string()
      .required("Email é obrigatório")
      .email("Email é inválido"),
    // phone: Yup.string().required("Telefone é obrigatório"),
    password: Yup.string()
      .required("Password é obrigatória")
      .min(8, "Password tem de ter pelo menos 8 caracteres")
      .max(12, "Password não pode ter mais que 12 caracteres"),
    confirmPassword: Yup.string()
      .required("Confirmação Password é obrigatória")
      .oneOf([Yup.ref("password"), null], "Password e confirmação diferentes"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Por favor indique se aceita os termos"
    ),
    country: Yup.object({ value: Yup.string() })
      .required("Campo Obrigatório")
      .nullable(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, control } =
    useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    register("country");
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    data = { ...data, captcha_value: captchaValue };
    setLoading(true);
    dispatch(signup(data))
      .then(() => {
        setLoading(false);
        setDone(true);
      })
      .catch(() => {
        setLoading(false);
        setDone(false);
      });
  };

  return (
    <>
      {done && (
        <Alert variant="success">
          Registo efectuado com sucesso. Vai receber um email para confirmar a
          sua conta.
        </Alert>
      )}

      {!loading && message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        </div>
      )}

      {!done && (
        <div className="card m-3">
          <div className="card-body">
            <h2 className="txt-darkorange bold">Registo Utilizador</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-group col">
                  <label>Nome</label>
                  <input
                    name="name"
                    type="text"
                    {...register("name")}
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Morada</label>
                  <input
                    name="address"
                    type="text"
                    {...register("address")}
                    className={`form-control ${
                      errors.address ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.address?.message}
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Código Postal - Localidade</label>
                  <div className="row">
                    <div className="col-1">
                      <Form.Control
                        name="cp1"
                        type="number"
                        {...register("cp1")}
                        className={`form-control ${
                          errors.cp1 ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.cp1?.message}
                      </div>
                    </div>
                    <div className="col-1">
                      <input
                        name="cp2"
                        maxLength={3}
                        type="number"
                        {...register("cp2")}
                        className={`form-control ${
                          errors.cp2 ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.cp2?.message}
                      </div>
                    </div>
                    <div className="col-10 text-center">
                      <input
                        name="locality"
                        type="text"
                        {...register("locality")}
                        className={`form-control ${
                          errors.locality ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.locality?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>País</label>

                  <Controller
                    name="country"
                    defaultValue={null}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => {
                      return (
                        <>
                          <Select
                            {...field}
                            options={options}
                            placeholder="País"
                            value={isEmpty(field.value) ? "" : field.value}
                            className={`form-control ${
                              errors.country ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.country?.message}
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <div className="form-group row">
                    <div className="form-group col-6">
                      <label>Email</label>
                      <input
                        name="email"
                        type="text"
                        {...register("email")}
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                    <div className="form-group col-6">
                      <label>Telefone</label>
                      <input
                        name="phone"
                        type="text"
                        {...register("phone")}
                        className={`form-control ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.phone?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <label>Password</label>
                  <input
                    name="password"
                    type="password"
                    {...register("password")}
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </div>
                <div className="form-group col">
                  <label>Confirmar Password</label>
                  <input
                    name="confirmPassword"
                    type="password"
                    {...register("confirmPassword")}
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.confirmPassword?.message}
                  </div>
                </div>
              </div>
              <div className="form-group form-check">
                <input
                  name="acceptTerms"
                  type="checkbox"
                  {...register("acceptTerms")}
                  id="acceptTerms"
                  className={`form-check-input ${
                    errors.acceptTerms ? "is-invalid" : ""
                  }`}
                />
                <label htmlFor="acceptTerms" className="form-check-label">
                  <b>
                    Aceita &nbsp;
                    <a onClick={() => setModalShow(true)} href="#">
                      termos e condições
                    </a>
                    .
                  </b>
                  <TermsAndConditions
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </label>
                <div className="invalid-feedback">
                  {errors.acceptTerms?.message}
                </div>
              </div>

              <div className="form-group col pt-5 d-flex justify-content-start">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE}
                  onChange={handleRecaptcha}
                  hl="pt-PT"
                />
              </div>
              <div className="form-group d-flex justify-content-end col-12">
                <button
                  type="button"
                  onClick={() => reset()}
                  className="btn btn-secondary w-25 m-1"
                >
                  Limpar
                </button>
                {captchaResult && (
                  <button type="submit" className="btn btn-primary m-1 w-25">
                    Registar
                  </button>
                )}
              </div>
            </form>
            <div className="form-group d-flex justify-content-start col-12">
              <p>
                A validação do registo será efetuada em breve pela Direção da
                SGP.
              </p>
            </div>
            <div className="form-group d-flex justify-content-end col-12">
              <p>
                Se nunca foi sócio e é a primeira vez que se inscreve, a
                validação está condicionada à verificação do bom pagamento da
                joia (25.00€) e da primeira quota (25.00€ para sócio regular e
                15.00€ para sócio estudante). O pagamento pode ser efetuado
                através de transferência bancária, utilizando:
              </p>
            </div>
            <div className="form-group d-flex justify-content-end col-12">
              <ul>
                <li>
                  <p>
                    <u>Home banking</u> para o NIB 0035 0675 00014690032 03 -
                    neste caso, pedimos que se identifique (nome próprio e
                    apelido) no descritivo da operação. De outra forma, pode não
                    ser possível associar o candidato a sócio a um número de
                    operação bancária gerado automaticamente pelo banco.
                  </p>
                </li>
                <li>
                  <p>
                    <u>Caixa Multibanco</u> - neste caso, solicitamos o envio de
                    digitalização ou fotografia do respetivo talão, para o
                    endereço webmaster@socgeol.pt. No caso dos estudantes deverá
                    ser também enviado comprovativo atualizado dessa qualidade.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
