/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { isEmpty, isNil } from "ramda";

const CarouselComponent = (props) => {
  const { topCarItems } = props;

  if (!!topCarItems) {
    return (
      <>
        <Carousel
          controls={false}
          indicators={false}
          fade={true}
          interval={5000}
        >
          {topCarItems.map((itemP, i) => {
            return (
              <Carousel.Item key={itemP.id}>
                <img
                  className="slide"
                  alt={
                    itemP.small_description
                      ? itemP.small_description
                      : itemP.description
                  }
                  src={
                    !isNil(itemP.image) && !isEmpty(itemP.image)
                      ? itemP.image
                      : !isEmpty(itemP.exp_url_image)
                      ? itemP.exp_url_image
                      : itemP.first_image
                  }
                />
                <Carousel.Caption>
                  <div className="pt-50">
                    <h1 className="text-shadow pb-0 text-uppercase">
                      {itemP.small_description
                        ? itemP.small_description
                        : itemP.description}
                    </h1>
                    {!!itemP.url && (
                      <p>
                        <Link
                          className="btn btn-md btn-success shadow"
                          to={itemP.url}
                        >
                          Saiba mais&nbsp;
                          <i
                            className="fas fa-angle-right"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </p>
                    )}
                  </div>
                </Carousel.Caption>
                <div className="lower-caption">
                  <h3 className="text-shadow pb-0 text-uppercase">
                    {itemP.down_description && itemP.down_description} &nbsp;
                  </h3>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!topCarItems} />
      </div>
    );
  }
};
export default CarouselComponent;
