import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import DocumentsService from "../services/documents.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";
import Loader from "./Loader";
import { isEmpty } from "ramda";

const Document = () => {
  const [document, setDocument] = useState();
  let { documentId } = useParams();

  useEffect(() => {
    DocumentsService.getDocumentById(documentId).then(
      (response) => {
        setDocument(response.data);
      },
      (error) => {
        const _document =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setDocument(_document);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [documentId]);

  if (!!document) {
    return (
      <DynamicContent
        title={`Documento - ${document.subject} - ${document.year}`}
        content={""}
      >
        {!isEmpty(document.scientific_areas) && (
          <p style={{ textAlign: "justify" }}>
            <b>Temas:</b> <br />{" "}
            {document.scientific_areas.map((a) => `${a.description}, `)}
          </p>
        )}

        <p style={{ textAlign: "justify" }}>
          <b>Autores:</b> <br /> {document.authors.map((a) => a.name)}
        </p>
        <p style={{ textAlign: "justify" }}>
          <b>Resumo:</b> <br /> {document.resume}
        </p>
        <p>
          <a href={document.exp_url} rel="noreferrer" target="_blank">
            Download
          </a>
        </p>
        {!isEmpty(document.keywords) && (
          <div>
            <p style={{ textAlign: "justify" }}>
              <b>Termos chave:</b>
            </p>
            <p
              style={{
                overfloWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
                whiteSpace: "normal",
              }}
            >
              {document.keywords.replaceAll(",", ", ")}
            </p>
          </div>
        )}
      </DynamicContent>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!document} />
      </div>
    );
  }
};

export default Document;
