/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import authHeader from "./auth-header";
import { API_PATH } from "../config";

const API_URL = `${API_PATH}v1/`;

const getUserQuotizations = () => {
  return axios.get(API_URL + "quotizations", { headers: authHeader() });
};

export default {
  getUserQuotizations,
};
