import React from "react";
import { Table } from "react-bootstrap";

const ProfileQuotes = (props) => {
  const { quotes } = props;
  return (
    <>
      <Table striped bordered>
        <thead>
          <tr>
            <th className="text-center">Ano</th>
            <th className="text-center">Valor</th>
            <th className="text-center">Pago</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map((item, index) => {
            return (
              <tr key={`r_${item.year}`}>
                <td className="text-center" key={`${item.year}_${index}`}>
                  {item.year}
                </td>
                <td className="text-center" key={`v_${item.year}_${index}`}>
                  {item.quotization} €
                </td>
                <td
                  className="text-center"
                  key={`p_${item.year}_${index}`}
                  style={{ color: item.value ? "#000000" : "red" }}
                >
                  {item.value ? "Pago" : "Não Pago"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="mt-4 mb-2">
        <h4> Como Pagar?</h4>
        <ul>
          <li style={{ ListStyleType: "decimal" }}>
            A partir de 1 de Janeiro de 2012:
            <br />
            Valor da jóia: 25,00 € (Euro);
            <br />
            Valor anual das quotas: 25,00 € (Euros) para sócios singulares;
            20,00 € (Euros) para sócios estudantes.
          </li>
          <br />
          <li style={{ ListStyleType: "decimal" }}>
            A regularização de quotas em atraso deverá ser efectuada através de:
            <br />
            <br />
            <ul>
              <li style={{ ListStyleType: "disc" }}>
                Pagamento por cheque enviado para o seguinte endereço:
                <br />
                Sociedade Geológica de Portugal
                <br />
                Rua da Escola Politécnica, 56, 58
                <br />
                1269-102 Lisboa
                <br />
                <br />
              </li>
              <li style={{ ListStyleType: "disc" }}>
                Pagamento por transferência bancária utilizando “
                <u>home banking</u>”; neste caso, solicitamos que deixe
                indicação clara da origem do pagamento no campo «descrição»
                (deverá escrever o seu nome próprio e o apelido);
              </li>
              <br />
              <li style={{ ListStyleType: "disc" }}>
                Pagamento por transferência bancária, utilizando uma{" "}
                <u>caixa Multibanco</u>; neste caso solicitamos o envio, para o
                webmaster, do respectivo talão comprovativo do pagamento.
              </li>
            </ul>
          </li>
          <br />
          <li style={{ ListStyleType: "decimal" }}>
            NIB da Sociedade Geológica de Portugal: 0035 0675 00014690032 03
            <br />
            Para esclarecimentos complementares, por favor contacte a Direcção
            através do email:{" "}
            <span className="link">webmaster(at)socgeol.pt</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfileQuotes;
