import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { processResetPassword } from "../actions/auth";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Input from "react-validation/build/input";
import { clearMessage } from "../actions/message";

const PasswordResetProcess = (props) => {
  const { token } = useParams();

  const checkBtn = useRef();
  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Campo obrigatório
        </div>
      );
    }
  };

  const form = useRef();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [done, setDone] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password é obrigatória")
      .min(8, "Password tem de ter pelo menos 8 caracteres")
      .max(12, "Password não pode ter mais que 12 caracteres"),
    confirmPassword: Yup.string()
      .required("Confirmação Password é obrigatória")
      .oneOf([Yup.ref("password"), null], "Password e confirmação diferentes"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { formState } = useForm(formOptions);
  const { errors } = formState;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const [password, setPassword] = useState("");
  const [passwordC, setPasswordC] = useState("");

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePasswordC = (e) => {
    const passwordC = e.target.value;
    setPasswordC(passwordC);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(
        processResetPassword({
          token: token,
          password: password,
          password_confirmation: passwordC,
        })
      )
        .then(() => {
          setDone(true);
          props.history.push("/");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      {done && (
        <Alert variant="success">
          Pedido efectuado com sucesso. Já pode fazer login.
        </Alert>
      )}
      {!done && (
        <div className="m-0 p-0 col-12">
          <div className="card-body  card card-container mx-auto">
            <h2 className="txt-darkorange bold ">Associar nova password</h2>

            <Form onSubmit={onSubmit} ref={form}>
              <div className="form-group ">
                <div className="form-group row">
                  <div className="form-group col-12">
                    <label className="mb-2">Password</label>

                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required]}
                    />

                    <div className="invalid-feedback">
                      {errors.password?.message}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-group col-12">
                    <label className="mb-2">Confirme Password</label>

                    <Input
                      type="password"
                      className="form-control"
                      name="passwordC"
                      value={passwordC}
                      onChange={onChangePasswordC}
                      validations={[required]}
                    />

                    <div className="invalid-feedback">
                      {errors.passwordC?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group d-flex justify-content-end col-12">
                <button type="submit" className="btn btn-primary mt-2 ">
                  Salvar{"  "}
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                </button>
              </div>
              <div className="mt-2">
                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </div>
            </Form>
            {!loading && message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordResetProcess;
