import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { clearMessage } from "../actions/message";
import { GernericModal } from "./GernericModal";

import { login } from "../actions/auth";

const Login = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email é obrigatório"),
    password: Yup.string().required("Password é obrigatória"),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } =
    useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    console.log(data);
    setLoading(true);

    dispatch(login(data.email, data.password))
      .then(() => {
        props.history.push("/");
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="col-md-12">
      <div className="card card-container mx-auto">
        <h2 className="txt-darkorange bold ">Login Sócio</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="username">Email</label>
            <input
              name="email"
              type="text"
              {...register("email")}
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              type="password"
              {...register("password")}
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>

          <div className="form-group mt-2 d-flex justify-content-end">
            <button type="submit" className="btn btn-primary m-1 w-35">
              <span>Entrar{"  "}</span>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </button>
          </div>
          <div className="mt-2">
            <Link to="/register" className="fas fa-angle-right">
              Novo registo
            </Link>
            <br />
            <Link to="/password_recover" className="fas fa-angle-right">
              Recuperar Password
            </Link>
            <br />
            <Link
              to="#"
              className="fas fa-angle-right"
              onClick={() => setModalShow(true)}
            >
              Perdeu os seus dados de acesso?
            </Link>
          </div>

          {message && (
            <div className="form-group mt-2 m-b-0">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
        </form>
        <GernericModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          closeButtonTitle={"Fechar"}
          title={"Perdeu os seus dados de acesso?"}
        >
          <p>Não se recorda do seu email de registo ou o seu email mudou?</p>
          <p>
            Por favor contacte-nos - <b>webmaster(at)socgeol.pt</b> - para o
            podermos ajudar a recuperar a sua conta.
          </p>
          <p>
            Obrigado, <br />
            Sociedade Geológica de Portugal
          </p>
        </GernericModal>
      </div>
    </div>
  );
};

export default Login;
