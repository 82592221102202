/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { dynamicFooter = [] } = props;
  return (
    <>
      <div className="row bg-lightest mt-3">
        <div className="col">
          <h3 className="txt-darkgreen text-left text-uppercase bold mt-3 mb-3">
            Apoios e Parceiros
          </h3>
        </div>
      </div>
      <div className="row bg-lightest p-3 text-center mb-5">
        <div className="col-3">
          <a href="https://www.fct.pt/" target="_blank" rel="noreferrer">
            <img
              src="/images/FCT_Logo_transparente.png"
              alt="FCT"
              title="FCT"
              className="img-fluid"
              style={{ maxHeight: "43px" }}
            />
          </a>
        </div>
        {/* <div className="col-sm-1 col-md-3 col-lg-2">
          <a href="#" target="_blank">
            <img
              src="/images/logo_aegs.png"
              alt="AEGS"
              title="AEGS"
              className="img-fluid"
            />
          </a>
        </div> */}
        <div className="col-3">
          <a
            href="https://www.icog.es/iageth/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/IAGETH_Final.png"
              alt="IAGETH"
              title="IAGETH"
              className="img-fluid"
              style={{ maxHeight: "60px" }}
            />
          </a>
        </div>
        <div className="col-3">
          <a href="https://www.inqua.org/" target="_blank" rel="noreferrer">
            <img
              src="/images/inqua-logo2.png"
              alt="INQUA"
              title="INQUA"
              className="img-fluid"
              style={{ maxHeight: "60px" }}
            />
          </a>
        </div>
        <div className="col-3">
          <a href="https://www.iugs.org/" target="_blank" rel="noreferrer">
            <img
              src="/images/IUGS-logo-transparente.png"
              alt="IUGS"
              title="IUGS"
              style={{ maxHeight: "60px" }}
              className="img-fluid"
            />
          </a>
        </div>
      </div>

      <div className="row text-center">
        <div className="col-12">
          <small>
            <div className="row">
              <div className=" d-flex flex-row ">
                {dynamicFooter.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Link
                        to={`/pages/${item.slug}`}
                        key={item.id}
                        className={`link dropdown-item d-flex ${
                          index === 0
                            ? "justify-content-end"
                            : "justify-content-start"
                        }`}
                      >
                        {item.description}
                      </Link>
                      {index === 0 || index + 1 < dynamicFooter.length ? (
                        <span className=" mt-1">|</span>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </small>
        </div>
      </div>
    </>
  );
};
export default Footer;
