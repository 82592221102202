import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import ActualitiesService from "../services/actualities.service";
import EventBus from "../common/EventBus";
import DynamicContent from "./DynamicContent";

const ActualityDetail = (props) => {
  const [actuality, setActuality] = useState();
  let { actualityId } = useParams();

  useEffect(() => {
    ActualitiesService.getActuality(actualityId).then(
      (response) => {
        setActuality(response.data);
      },
      (error) => {
        const _actuality =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setActuality(_actuality);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, [actualityId]);

  return (
    !!actuality && (
      <DynamicContent
        title={actuality.small_description || ''}
        content={actuality.description || ''}
      />
    )
  );
};

export default ActualityDetail;
